import { logger } from './logger';
export function registerNewElement(shell) {
    class MicroFrontend extends HTMLElement {
        constructor() {
            super();
        }
        connectedCallback() {
            this.loadMicroFrontend();
        }
        loadMicroFrontend() {
            const app = this.getAttribute('app');
            const shadowRoot = false;
            if (!app) {
                logger.error('micro-frontend requires app attribute!');
                return;
            }
            try {
                if (shadowRoot) {
                    const container = this.attachShadow({ mode: 'open' });
                    this.appendChild(container);
                }
                shell.mountMicroFrontend(app, this);
            }
            catch (error) {
                logger.error('Error loading micro-frontend:', error);
            }
        }
    }
    customElements.define('micro-frontend', MicroFrontend);
}
