import { isDevelopmentMode } from './utils';
class Logger {
    info(...data) {
        if (isDevelopmentMode()) {
            console.log(...data);
        }
    }
    warn(...data) {
        if (isDevelopmentMode()) {
            console.warn(...data);
        }
    }
    error(...data) {
        if (isDevelopmentMode()) {
            console.error(...data);
        }
    }
}
export const logger = new Logger();
