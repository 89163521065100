var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { logger } from './logger';
import './microfrontend-element';
(function bootstrap() {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const initializeShelP = import('./core/Shell');
            const init = yield initializeShelP;
            const shell = init.initializeShell(window.__shell_config);
            (yield import('./microfrontend-element')).registerNewElement(shell);
            const rootMicrofrontend = document.createElement('micro-frontend');
            rootMicrofrontend.setAttribute('app', 'RootApp');
            const rootDiv = document.getElementById('root-microfrontend');
            rootDiv === null || rootDiv === void 0 ? void 0 : rootDiv.appendChild(rootMicrofrontend);
        }
        catch (error) {
            logger.error('Error loading remote micro-frontend:', error);
        }
    });
})();
